<template>
    <div class="d-flex report-box-parent mt-3 mb-lg-3 mb-0">
        <div class="flex-1" v-for="(item, index) in data" :key="index" :class="loading ? 'dashboard-load-div' : ''">
            <div class="report-box">
                <div class="report-box-title">
                    {{item.label}}
                </div>
                <div class="report-box-value">
                    <ProgressSpinner v-if="loading"/>
                    <span v-else>{{ item?.value ? $commonFuction.numberFormat(item?.value, 0) : '-' }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    data: Array,
    loading: Boolean
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.report-box {
    background: #e395b61a;
    border: 1px solid #e395b61a;
    height: 80px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .report-box-title {
        font-size: 16px;
        color: #333333;
        height: 30px;
        text-align: center;
        line-height: 17px;
    }
    
    .report-box-value {
        color: #C40380;
        font-size: 24px;
        font-weight: bold;
    }
}
.manager-title {
    font-size: 24px;
}

.dashboard-load-div {
    opacity: .5;
    .p-progress-spinner {
        width: 30px;
        height: 30px;
    }
}

@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #C40380;
    }
    40% {
        stroke: #C40380;
    }
    66% {
        stroke: #C40380;
    }
    80%,
    90% {
        stroke: #C40380;
    }
}
.report-box-parent {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.flex-1 {
  flex: 1 !important;
//   min-width: 205px;
//   width: auto;
//   padding: 10px 10px 30px;
}

// .flex-1:first-child {
//   padding-left: 0;
// }

// .flex-1:last-child {
//   padding-right: 0;
// }

/* Media query for smaller screens */
@media (max-width: 960px) {
    .report-box-parent{
        margin-left: 10px !important;
    }
  .report-box-parent .flex-1 {
    flex: 0 0 50%; /* Set the flex-basis to 50% for smaller screens */
    width: 155px !important;
    max-width: 50% !important;
    padding: 10px; /* Adjust padding for smaller screens */
  }
}
</style>