<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-10 col-12 row pr-0">
            <div class="col-lg-4 col-12 pr-0">
                <label class="p-col-fixed txt-left p-0 m-0">Xem thống kê</label>
                <div class="p-col p-0 pt-1">
                  <Calendar 
                    :disabled="channelLoading || totalLoading || orderLoading"
                    @clear-click="headerOnFilter"
                    @date-select="headerOnFilter"
                    v-model="dateReport" placeholder="DD/MM/YYYY - DD/MM/YYYY" class="w-100" appendTo="body" :showButtonBar="true" selectionMode="range" dateFormat="dd/mm/yy" :showIcon="true"/>
                </div>
            </div>
            
            <div class="col-lg-4 col-12 pr-0">
                <label class="p-col-fixed txt-left p-0 m-0">Công ty</label>
                <div class="p-col p-0 pt-1">
                  <v-select :disabled="channelLoading || totalLoading || orderLoading" :clearable="false" title="Công ty" id="select_company_id" :filterable="false" placeholder="Chọn" v-model="selCompany" :options="listCompany" label="code" @search="fetchOptionsCompany">
                      <template #selected-option="{ code, name }">
                        <em><b>{{code}}</b> - {{name}}</em>
                      </template>
                      <template #option="{ code, name }">
                        <em><b>{{code}}</b> - {{name}}</em>
                      </template>
                      <span slot="no-options" @click="$refs.select.open = false">
                          Không có dữ liệu
                      </span>
                  </v-select>
                  <input class="input_tmp_validator" id="company_id">
                </div>
            </div>
            
            <div class="col-lg-4 col-12 pr-0">
                <label class="p-col-fixed txt-left p-0 m-0">Kênh phân phối</label>
                <div class="p-col p-0 pt-1">
                  <v-select :disabled="channelLoading || totalLoading || orderLoading" :clearable="false" title="Kênh phân phối" id="select_channel_id" :filterable="false" placeholder="Chọn" v-model="selChannel" :options="listChannel" label="code" @search="fetchOptionsChannel">
                      <template #selected-option="{ code, name }">
                        <em><b>{{code}}</b> - {{name}}</em>
                      </template>
                      <template #option="{ code, name }">
                        <em><b>{{code}}</b> - {{name}}</em>
                      </template>
                      <span slot="no-options" @click="$refs.select.open = false">
                          Không có dữ liệu
                      </span>
                  </v-select>
                  <input class="input_tmp_validator" id="company_id">
                </div>
            </div>
          </div>
          <div class="col-lg-2 col-12 d-flex justify-content-end mt-lg-0 mt-3">
            <Button :disabled="channelLoading || totalLoading || orderLoading" v-if="this.role.export == true" label="Xuất excel" class="main-style-button p-3" @click="exportExcel" />
          </div>
        </div>
        <BlockTotal :data.sync="total_report" :loading.sync="totalLoading"/>
        <!--{{listData}}-->
        <ListChannel
          :key="`C-${componentKey}`"
          :tableData="listData"
          :model="dataModel"
          :options="options"
          :filterDefault.sync="filters"
          :getFilterOptions="getFilterOptions"
          v-on:action-click="onActionClicked"
          @onDataChange = 'DataOnChange'
          @getFilters="getChannelFilters"
          :loading.sync="channelLoading"
          :listDataService.sync="channel_report"
        ></ListChannel>
        <br/>
        <ListOrder
          :key="`O-${componentKey}`"
          :tableData="listData"
          :model="dataModel"
          :options="options"
          :filterDefault.sync="filters"
          :getFilterOptions="getFilterOptions"
          v-on:action-click="onActionClicked"
          @onDataChange = 'DataOnChange'
          @getFilters="getOrderFilters"
          :loading.sync="orderLoading"
          :listDataService.sync="order_report"
          :totalRecords.sync="order_report_count"
        ></ListOrder>
        <!-- @loading="(value) => {this.loading = value}" -->

        <Dialog header="Thông báo" :visible.sync="popupWarning" id="warning_poins_errs">
          <span class="txt-center">
            File đang được xuất, chúng tôi sẽ gửi file qua email của bạn.
          </span>
          <template
              #footer
              class="justify-content-center"
            >
            <Button label="Xác nhận" @click="popupWarning = false" icon="pi pi-check" class="" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import DataServices from '@/core/DataServices';
import ListChannel from './list';
import BlockTotal from './blockTotal';
import ListOrder from './listOrder';
import ApiRepository from "@/core/ApiRepository";
import AuthenticationApp from "@/core/AuthenticationApp";
// import gql from "graphql-tag";
// import Popup from './popup'
export default {
  components: {
    ListChannel,
    BlockTotal,
    ListOrder
    // Popup
  },
  data() {
    return {
      popupWarning: false,
      first: 0,
      rows: 20,
      filters : {},
      filterOptions: {},
      options: [],
      listData: [],
      modelName: '',
      dataModel: null,
      title: '',
      componentKey: 0,
      editId: null,
      displayDialog: false,
      editDialogKey: 0,
      dialogMode: 'edit',
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      whereKeyList:{
        where_key: {
          /*accounts: {
            role: {_eq: "admin"},
            deleted: {_eq: false}
          },*/
          deleted: {_eq: false}
        }
      },
      page_transaction:null,
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        export: false,
      },
      view_club: false,
      listCompany: [],
      listCompanyAll: [],
      selCompany: null,
      listChannel: [],
      listChannelAll: [],
      selChannel: null,
      channelLoading: false,
      totalLoading: false,
      orderLoading: false,
      dateReport: null,
      loading: false,
      order_report_count: 0,
      order_report: [],
      channel_report: [],
      // total_report: [],
      total_report: [
        {
          label: 'Tổng đơn hàng',
          value: 0
        },
        {
          label: 'Đơn tồn động kỳ trước',
          value: 0
        },
        {
          label: 'Đơn hàng trong kỳ',
          value: 0
        },
        {
          label: 'Đơn giao thành công',
          value: 0
        },
        {
          label: 'Đơn chưa giao thành công',
          value: 0
        },
        {
          label: 'Đơn đang giao hàng',
          value: 0
        },
        {
          label: 'Đơn chưa giao hàng',
          value: 0
        }
      ],
      arrDeliveryType: [{
          value: 1,
          label: 'Giao hàng đến địa chỉ khách',
      },{   
          value: 2,
          label: 'Giao hàng đến chành',
      },{
          value: 3,
          label: 'Gửi bưu điện',
      },{
          value: 4,
          label: 'Tự lấy hàng'
      }],
      changeFilter: 0
    }
  },
  mounted () {
    this.initParams();
    // if (!this.channelLoading) {
    //   this.getChannelData();
    // }
    // if (!this.totalLoading) {
    //   this.getTotalData();
    // }
    // if (!this.orderLoading) {
    //   this.getOrderData();
    // }
  }
  ,
  async created() {
    this.modelName = this.$route.meta.model;
    this.dataModel = DataServices.getModel(this.modelName);
    //console.log('this.dataModel:',this.dataModel);
    this.title = this.dataModel.name
    this.$store.commit('setLoading', true);
    if(this.$route.params.reload) {
      window.location.reload();
    }
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_REPORT_ORDER_PROGRESS);
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
      console.log("asiasiai")
      this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    }
    // console.log("arr_role:" ,arr_role)
    this.role.export = (arr_role.indexOf(this.$constants.ROLE.EXPORT) < 0) ? false:true;
    this.role.edit = (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) ? false:true;
    this.role.delete = (arr_role.indexOf(this.$constants.ROLE.DELETE) < 0) ? false:true;

    // var arr_role_clubs = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_CLUBS);
    // // console.log('role_clubrole_club',arr_role_clubs)
    // if (arr_role_clubs.indexOf(this.$constants.ROLE.VIEW) < 0){
    //   this.view_club = false;
    // }else{
    //   this.view_club = true;
    // }
    // console.log('this.view',this.view_club)
  },
  watch: {
    '$route': 'initParams',
    // "dateReport" : {
    //   async handler()
    //   {
    //     // this.loading = true;
    //     if (this.dateReport) {
    //       this.$set(this.filters, 'date_order_filter', {value: this.dateReport, type_input: 'DateRange'});
    //     } else {
    //       console.log(this.dateReport);
    //       this.$delete(this.filters, 'date_order_filter');
    //     }
    //     // setTimeout(() => {
    //     //   this.loading = false;
    //     // }, 200)
    //     if (!this.channelLoading) {
    //       this.getChannelData();
    //     }
    //     if (!this.totalLoading) {
    //       this.getTotalData();
    //     }
    //     if (!this.orderLoading) {
    //       this.getOrderData();
    //     }
    //     return;
    //   }
    // },
    "selCompany"() {
        // this.loading = true;
        if (this.selCompany?.id) {
            this.$set(this.filters, 'company_id', {value: this.selCompany?.id, type_input: 'select'});
        } else {
            this.$delete(this.filters, 'company_id');
        }
        // setTimeout(() => {
        //   this.loading = false;
        // }, 200)
        if (!this.channelLoading) {
          this.getChannelData();
        }
        if (!this.totalLoading) {
          this.getTotalData();
        }
        if (!this.orderLoading) {
          this.getOrderData();
        }
        return;
    },
    "selChannel"() {
        // this.loading = true;
        if (this.selChannel?.id) {
            this.$set(this.filters, 'channel_id', {value: this.selChannel?.id, type_input: 'select'});
        } else {
            this.$delete(this.filters, 'channel_id');
        }
        // setTimeout(() => {
        //   this.loading = false;
        // }, 200)
        if (!this.channelLoading) {
          this.getChannelData();
        }
        if (!this.totalLoading) {
          this.getTotalData();
        }
        if (!this.orderLoading) {
          this.getOrderData();
        }
        return;
    }
  },
  methods: {
    headerOnFilter() {
      this.changeFilter = 0;
      this.change_filter();
      if (this.changeFilter > 0) {
        if (!this.channelLoading) {
          this.getChannelData();
        }
        if (!this.totalLoading) {
          this.getTotalData();
        }
        if (!this.orderLoading) {
          this.getOrderData();
        }
      }
    },
    async change_filter() {
      if (this.dateReport) {
        if (!this.$commonFuction.isEmpty(this.dateReport) && !this.$commonFuction.isEmpty(this.dateReport[0]) && !this.$commonFuction.isEmpty(this.dateReport[1])) {
          this.$set(this.filters, 'date_order_filter', {value: this.dateReport, type_input: 'DateRange'});
          this.changeFilter++;
        }
      } else {
        this.$delete(this.filters, 'date_order_filter');
        this.changeFilter++;
      }
    },
    async getListCompany() {
      var whereAttach ={}
      var listDisplayCompany = AuthenticationApp.getListCompany();
      if (listDisplayCompany != null) {
        //order_shipping(where: {order_shipping_items: {order: {company_id: {_in: ""}}}})
        whereAttach.id =  {_in: listDisplayCompany};
      }
      var list =await this.$CoreService.getCompanyFilter(whereAttach);
        var list_Region = [];
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_Region.push({id : e.id, name: e.name, code: e.code});
        }
        this.listCompany = list_Region;
        this.listCompanyAll = list_Region;
    },
    
    async fetchOptionsCompany(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('company', {"fields" : `id code name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_Region = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_Region.push({id : e.id, name: e.name, code: e.code});
        }
        this.listCompany = list_Region;
      }else{
        // cosnole.log("dasdasd")
        this.listCompany = this.listCompanyAll
      }
    },
    async getListChannel() {
        var where_key = {
            deleted : {_eq : false},
        }
        let {data} = await this.$apollo.query({
            query: DataServices.getList('channel', {"fields" : `id code name`}),
            variables: {
            where_key: where_key,
            orderBy:[{created_at : "desc" }]
            },
            fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_Region = [];
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_Region.push({id : e.id, name: e.name, code: e.code});
        }
        this.listChannel = list_Region;
        this.listChannelAll = list_Region;
    },
    
    async fetchOptionsChannel(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('channel', {"fields" : `id code name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_Region = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_Region.push({id : e.id, name: e.name, code: e.code});
        }
        this.listChannel = list_Region;
      }else{
        // cosnole.log("dasdasd")
        this.listChannel = this.listChannelAll
      }
    },
    async initParams() {
      this.$store.commit('setLoading', true);
      this.modelName = this.$route.meta.model;
      this.dataModel = DataServices.getModel(this.modelName);
      this.title = this.dataModel.label;
      await this.getListCompany();
      this.$set(this, 'dateReport', [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()]);
      console.log("this.listCompanythis.listCompany:",this.listCompany)
      if (this.listCompany.length > 0){
        this.$set(this, 'selCompany', this.listCompany[0]);
      }
      this.getListChannel();
      for (let i =0; i<this.dataModel.displayFields.length; i++) {
        this.callbackOptions(i);
      }
      this.headerOnFilter();
    },
    async callbackOptions(i=null) {
      var fields = this.dataModel.getDisplayFields(i);
      for (let index = 0; index < fields.length; index++) {
        var attributes = [];
        const element = fields[index];
        if (element.models !== undefined && element.models !== null) {
          // console.log("SDFGHJK",element)
          var optionsData = [];
          attributes.push(element.models.key.key);
          attributes.push(element.models.display_key);
          var where_ = {}
          var result;
          if( element['name']== "service_name"){
            // console.log("element",element)
            let {data} = await this.$apollo.query({
              query: DataServices.getList("service", {"fields" : "id name"}),
              variables: {
                where_key : {
                  deleted : {_eq: false},
                  parent_id : {_is_null: true}
                },
                orderBy:[{ordinal: 'desc'},{created_at : 'desc'}]
              }
            })
            if (data) {
              result = data[Object.keys(data)];
              if (result) {
                for (let index = 0; index < result.length; index++) {
                  const ele = result[index];
                  optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
                }
              }
            }
          }else if( element['name']== "channel_code"){
            // console.log("element",element)
            let {data} = await this.$apollo.query({
              query: DataServices.getList("channel", {"fields" : "id code"}),
              variables: {
                where_key : {
                  deleted : {_eq: false},
                  //parent_id : {_is_null: true}
                },
                orderBy:[{created_at : 'desc'}]
              }
            })
            if (data) {
              result = data[Object.keys(data)];
              if (result) {
                for (let index = 0; index < result.length; index++) {
                  const ele = result[index];
                  optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
                }
              }
            }
          }
          else{
            let {data} = await this.$apollo.query({
              query: DataServices.getList(element.models.tableName, {"fields" : attributes.join(',')}),
              variables: {where_key : where_}
            })
            if (data) {
              result = data[Object.keys(data)];
              if (result) {
                for (let index = 0; index < result.length; index++) {
                  const ele = result[index];
                  optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
                }
              }
            }
          }
          // console.log("optionsData",optionsData)
          this.options[element.key] = optionsData;
        }
        this.componentKey++;
      }
    },
    DataOnChange(data,f){
      if(f.type == 'Checkbox'){
        let dataUpdate = {}
        if(f.key == 'active'){
          dataUpdate['active'] = data[f.key]
        }
        let variables = {
          'data_update': dataUpdate,
          'where_key': {
            'id': {'_eq': data.id}
          }
        };
        this.$apollo.mutate({
          mutation: DataServices.updateData(this.modelName),
          variables: variables
        }).then(() => {})
      }
    },
    onChange() {
      // this.$store.commit('setLoading', true);
      // this.$apollo.queries.listData.refresh()
    },
    getFilterOptions(name, index) {
      var field = this.dataModel.getField(name, index)
      var result = []
      if (field.type == 'Selection') {
        if (field.options ) result = field.options;
        else if(field.models && this.options[name]) result = this.options[name];
      } else {
        result = this.filterOptions[name] != null ? [...this.filterOptions[name]] : []
      }
      return result;
    },
    getRowActions() {
      var actions = {}
      // if (this.role.add) {
      //   actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
      // }
      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {}
        // if (this.role.edit) {
        //   buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `list-${this.modelName}/edit/`};
        // }
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${this.modelName}/edit/`}
        // if (this.role.delete) {
        //   buttons.delete = {name: 'delete', icon: 'trash', key: 'id'}
        // }
        if (this.dataModel.actions) {
          var modelaction = this.dataModel.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return actions
    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          break
        case 'reexamine':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key],query: { reexamine: true }  })
          } else  {
            this.addId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    getChannelFilters(filters) {
        // this.filters = Object.assign({...this.filters, ...filters});
        this.filters.filter_channel = {...this.filters, ...filters};
        console.log(this.filters);
        if (!this.channelLoading) {
          return this.getChannelData();
        }
        return;
    },
    getOrderFilters(lazyParams) {
      const {filters, first, rows} = lazyParams;
      this.filters.filter_order = {...this.filters, ...filters};
      this.first = first;
      this.rows = rows;
      if (!this.orderLoading) {
        return this.getOrderData();
      }
      return;
    },
    async getChannelData() {
      // this.lazyParams.filters = this.filtersCustom;
      var headers_channel = {
        "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
      }
      this.channelLoading = true;
      
      console.log("this.filters?.filter_channel", this.filters?.filter_channel);
      var filter_channel = {};
      if (this.filters?.filter_channel) {
        for (const [key, value] of Object.entries(this.filters?.filter_channel)) {
          if (key != 'filter_order' && key != 'filter_channel') {
            filter_channel[key] = value.value;
          }
        }
      } else {
        for (const [key, value] of Object.entries(this.filters)) {
          if (key != 'filter_order' && key != 'filter_channel') {
            filter_channel[key] = value.value;
          }
        }
      }
      ApiRepository.post("_api/common/report_order_progess_channel", {filters: filter_channel}, {headers: headers_channel}).then((res) => {

        const {data} = res;
        this.channel_report = data?.data?.channel_report || [];
        this.channel_report.map((m, i) => {
          m.stt = (i+1);
        })
        this.channelLoading = false;
      }).catch(async error => {
          console.log('error', error);
          this.$toast.add({
              severity: 'error',
              summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000
          });
          this.channelLoading = false;
      });
    },
    async getTotalData() {
      // this.lazyParams.filters = this.filtersCustom;
      this.totalLoading = true;
      var headers = {
        "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
      }
      
      var filter = {};
      if (this.filters) {
        for (const [key, value] of Object.entries(this.filters)) {
          if (key != 'filter_order' && key != 'filter_channel') {
            filter[key] = value.value;
          }
        }
      }
      ApiRepository.post("_api/common/report_order_progess_total", {filters: filter}, {headers}).then((res) => {

        const {data} = res;
        this.total_report = data?.data?.total_report || [
          {
            label: 'Tổng đơn hàng',
            value: 1538
          },
          {
            label: 'Đơn tồn động kỳ trước',
            value: null
          },
          {
            label: 'Đơn hàng trong kỳ',
            value: 1538
          },
          {
            label: 'Đơn giao thành công',
            value: 1448
          },
          {
            label: 'Đơn chưa giao thành công',
            value: 90
          },
          {
            label: 'Đơn đang giao hàng',
            value: 38
          },
          {
            label: 'Đơn chưa giao hàng',
            value: 52
          }
        ];
        this.totalLoading = false;
      }).catch(async error => {
          console.log('error', error);
          this.$toast.add({
              severity: 'error',
              summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000
          });
          this.totalLoading = false;
      });
    },
    async getOrderData() {
      // this.lazyParams.filters = this.filtersCustom;
      this.orderLoading = true;
      var headers = {
        "Authorization": "Bearer "+( await this.$AuthenticationApp.getToken()),
      }
      
      var filter = {};
      if (this.filters?.filter_order) {
        for (const [key, value] of Object.entries(this.filters?.filter_order)) {
          if (key != 'filter_order' && key != 'filter_channel') {
            filter[key] = value.value;
          }
        }
      } else {
        for (const [key, value] of Object.entries(this.filters)) {
          if (key != 'filter_order' && key != 'filter_channel') {
            filter[key] = value.value;
          }
        }
      }
      ApiRepository.post("_api/common/report_order_progess_order", {filters: filter, first: this.first, rows: this.rows}, {headers}).then((res) => {

        const {data} = res;
        this.order_report = data?.data?.order_report || [];
        
        this.order_report_count = data?.data?.count || 0;
        var stt = this.first;
        
        this.order_report.forEach((m) => {
          stt++;
          m.stt = stt;
          m.order_code = m.code || '-';
          m.doc_code = m.doc_code || '-';
          m.customer_code = m.customer_code || '-';
          m.suggest_delivery_type = m?.suggest_delivery_type ? this.arrDeliveryType.filter(f => f.value == m?.suggest_delivery_type)[0].label : '-';
          m.customer_name = m.customer_name || '-';
          m.group_channel_code = m?.customer?.group_channel?.code || '-';
          m.total_package = m?.total_package || 0;
          m.delivery_eligibility_date = m?.request_payment ? m?.date_paid : m?.doc_created_at;
          m.date_done = m?.date_done || null;
          m.order_status = m?.current_status?.name || '-';
          if (m?.request_payment && !m?.date_paid) {
            m.type_order_status = 'Chờ thanh toán';
          } else {
            m.type_order_status = m?.current_status?.name || '-';
          }
          m.channel_code = m?.customer?.channel?.code || '-';
        })
        this.orderLoading = false;
      }).catch(async error => {
          console.log('error', error);
          this.$toast.add({
              severity: 'error',
              summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000
          });
          this.orderLoading = false;
      });
    },
    async exportExcel() {
      //if (this?.dateReport && this.dateReport.length > 1) {
        //var datediff = (this.dateReport[1].getTime() - this.dateReport[0].getTime()) / (1000 * 60 * 60 * 24);
        //if (datediff > 35) {
          //alert("Vui lòng chọn khoảng thời gian < 35 ngày");
          //return;
        //}
      //} else {
        //alert("Vui lòng chọn khoảng thời gian < 35 ngày");
        //return;
      //}
      this.orderLoading = true;
      this.channelLoading = true;
      this.totalLoading = true;
      var headers = {
        "Authorization": "Bearer "+( await this.$AuthenticationApp.getToken()),
      }
      
      var filter_order = {};
      if (this.filters?.filter_order) {
        for (const [key, value] of Object.entries(this.filters?.filter_order)) {
          if (key != 'filter_order' && key != 'filter_channel') {
            filter_order[key] = value.value;
          }
        }
      } else {
        for (const [key, value] of Object.entries(this.filters)) {
          if (key != 'filter_order' && key != 'filter_channel') {
            filter_order[key] = value.value;
          }
        }
      }
      
      var filter = {};
      if (this.filters) {
        for (const [key, value] of Object.entries(this.filters)) {
          if (key != 'filter_order' && key != 'filter_channel') {
            filter[key] = value.value;
          }
        }
      }
      
      var filter_channel = {};
      if (this.filters?.filter_channel) {
        for (const [key, value] of Object.entries(this.filters?.filter_channel)) {
          if (key != 'filter_order' && key != 'filter_channel') {
            filter_channel[key] = value.value;
          }
        }
      } else {
        for (const [key, value] of Object.entries(this.filters)) {
          if (key != 'filter_order' && key != 'filter_channel') {
            filter_channel[key] = value.value;
          }
        }
      }
      
      ApiRepository.post("_api/common/report_order_progess_export", {filters: {filters: filter, filter_channel, filter_order}, first: this.first, rows: this.rows}, {headers}).then((res) => {
        console.log("resresresresresresresresresres", res);
        this.orderLoading = false;
        this.channelLoading = false;
        this.totalLoading = false;
        // if (res?.data?.data?.linkExcel) {
        //   window.open(res?.data?.data?.linkExcel);
        // }
        this.popupWarning = true;
      }).catch(async error => {
          console.log('error', error);
          this.$toast.add({
              severity: 'error',
              summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000
          });
          this.orderLoading = false;
          this.channelLoading = false;
          this.totalLoading = false;
      });
    }
  },

}
</script>
<style lang="css">
.p-calendar .p-datepicker { width: 280px; padding: 0px; padding-top: 5px;}
.p-calendar .p-datepicker td, .p-calendar .p-datepicker td a {padding: 0px !important;}
.p-calendar .p-datepicker td span {padding: 0px !important; width: 30px !important; height: 30px !important;}
.p-datepicker .p-datepicker-header{padding: 5px !important;}
.p-button-warning-o .p-button-label-o{
  color: #142053;
}
.p-button-warning .pi-times{
  color: #142053;
}
</style>